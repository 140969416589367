import { Props } from './types';

export enum Location {
  'US' = 'US',
}

export enum Timezone {
  'US' = 'America/Boise',
}

export enum FacilityId {
  'US' = 'hq',
}

export const INITIAL_VALUES: Props = {
  location: Location.US,
  facilityId: FacilityId.US,
  facilityTz: Timezone.US,
  setLocation: () => null,
  setFacilityId: () => null,
  setFacilityTz: () => null,
};
